import { styled } from 'styled-components';
import {
  Container,
  ContentContainer,
  ControlsContainer,
  Text,
  Title,
  Link,
  Button,
} from './Common';
import { TextField, PasswordTextField } from '@hn-ui/textfields';
import { useState } from 'react';
import { Divider } from './Divider';
import { CleverButton } from './CleverButton';
import { ClasslinkButton } from './ClasslinkButton';
import { useNavigate } from '@remix-run/react';
import type { IErrorMessage } from './types';
import { teacherAuthUrls } from 'app/utils/urls.data';

interface ILoginProps extends IErrorMessage {
  classLinkUrl: string;
  cleverUrl: string;
}

const RegisterText = styled(Text)`
  font-weight: 700;
`;

const Login = ({ classLinkUrl, cleverUrl, error, ...rest }: ILoginProps) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isFormValid = email.length > 0 && password.length > 0;
  const validation = Boolean(error)
    ? {
        validate: false,
        errorText: error,
      }
    : undefined;

  return (
    <Container {...rest}>
      <Title>Sign In to Happy Numbers</Title>
      <ContentContainer>
        <TextField
          placeholder="Email"
          label="email"
          name="email"
          onChange={(e) => setEmail(e.target.value.trim())}
          autofocus
          validateOnMount={false}
          autocomplete="email"
          validation={{ validate: !Boolean(error), errorText: '' }}
          compact="bottom"
        />
        <PasswordTextField
          label="Password"
          placeholder="Password"
          name="password"
          option={{
            text: 'I forgot my password',
            onClick: () =>
              navigate({
                pathname: teacherAuthUrls.resetPasswordResetEmail,
                search: email ? `?email=${email}` : '',
              }),
          }}
          onChange={(e) => setPassword(e.target.value.trim())}
          autocomplete="current-password"
          validation={validation}
        />
      </ContentContainer>
      <ControlsContainer>
        <Button type="submit" disabled={!isFormValid}>
          Sign In
        </Button>
        <RegisterText>
          New to Happy Numbers?{' '}
          <Link to={teacherAuthUrls.signup} bold>
            Create an account
          </Link>
        </RegisterText>
        <Divider text="OR" />
        <CleverButton
          text="Continue with Clever"
          onClick={() => (window.location.href = cleverUrl)}
        />
        <ClasslinkButton
          text="Sign in with Classlink"
          onClick={() => (window.location.href = classLinkUrl)}
        />
      </ControlsContainer>
    </Container>
  );
};

export { Login };

import { Login } from '@components/auth/Login';
import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node';
import { redirectDocument } from '@remix-run/node';
import { Form, useLoaderData } from '@remix-run/react';
import { useActionError } from 'app/hooks/useActionError';
import { GO_URL, teacherAuthUrls } from 'app/utils/urls.data';
import { cryptData, getAuthData, login } from 'app/utils/auth.server';
import { useCSRF } from 'app/hooks/useCSRF';
import { validateCSRFToken } from 'app/utils/session.server';
import { CsrfField } from '@components/CsrfField';

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const data = await getAuthData(request);

  return { data };
};

export const action = async ({ request }: ActionFunctionArgs) => {
  await validateCSRFToken(request);

  const formData = await request.formData();
  const email = formData.get('email')?.toString();
  const password = formData.get('password')?.toString();

  if (!email || !password) return { error: 'Email and password are required' };

  const { error, accountType, cookies } = await login(email, password, request);

  if (error) return { error };

  if (accountType && ['clever', 'class_link'].includes(accountType)) {
    const token = await cryptData(email);
    if (accountType === 'clever')
      return redirectDocument(`${teacherAuthUrls.clever}?token=${token}`);
    if (accountType === 'class_link')
      return redirectDocument(`${teacherAuthUrls.classlink}?token=${token}`);
  }

  const headers = cookies?.map((c) => ['Set-Cookie', c]) as [string, string][];

  return redirectDocument(GO_URL, {
    headers: [...headers],
  });
};

export default function LoginPage() {
  const { data } = useLoaderData<typeof loader>();
  const { errorMessage, releaseErrorMessage } = useActionError<typeof action>();
  const { csrfToken } = useCSRF();

  return (
    <Form method="POST" onChange={releaseErrorMessage}>
      <CsrfField value={csrfToken} />
      <Login
        classLinkUrl={data.classLinkUrl}
        cleverUrl={data.cleverUrl}
        error={errorMessage}
      />
    </Form>
  );
}
